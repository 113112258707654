import React from 'react';

import HorizontalNav2 from '../components/horizontal-navs/HorizontalNav2';
import Header1 from '../components/headers/Header1';
import Features1 from '../components/features/Features1';
import HowItWorks2 from '../components/how-it-works/HowItWorks2';

export default function Index() {
  return (
    <React.Fragment>
      <HorizontalNav2
        content={{
          brand: {
            text: 'Smartogram Innovation',
            image: '/images/logo.svg',
            width: '110',
          },
          link1: 'Products',
          link2: 'Consulting',
          link3: 'Trainings',
          link4: 'Contact us',
          'primary-action': 'Login',
        }}
      />

      <Header1
        content={{
          'header-p1': 'Digital Strategy ',
          'header-p2':
            'is the Need of the hour to be a responsive enterprise',
          description:
            'Technology partners for digital transformation journey.',
          'primary-action': 'Contact us',
        }}
      />

      <Features1
        content={{
          'header-p1': 'Transformations',
          'header-p2': 'that are helping traditional business to scale ',
          description: 'what do we do?',
          'col1-header': 'Agile Transformation',
          'col1-desc':
            ' Transitioning an entire organization to a nimble, responsive approach based on agile principles.',
          'col2-header': 'Digital Transformation',
          'col2-desc':
            ' Integration of digital technology into all areas of a business, fundamentally changing how you operate and deliver value faster to customers.',
          'col3-header': 'Process Automation',
          'col3-desc':
            'Application of technology, governed by business logic and structured inputs, aimed at automating business processes and hence increasing productivity',
          'col4-header': 'Cloud Migration',
          'col4-desc':
            'Transforming your cloud-first intentions into reality by aligning your cloud strategy with your business goals.',
          'col5-header': 'Leadership',
          'col5-desc':
            'Sustaining organization-wide process of helping individuals  undergo the necessary growth mindset for change management',
          'col6-header': 'Business Agility',
          'col6-desc':
            'Transforming organizations to Adapt quickly to market changes - internally and externally. Respond rapidly and flexibly to customer demands.',
        }}
      />

      <HowItWorks2
        content={{
          badge: 'How do we do?',
          'header-p1': 'Define and Measure what matters most',
          'header-p2': 'and we accelerate this journey',
          description:
            'We have built products that uses automated indicators that keeps track of the progress of the transformation and initiatives. With real-time feedback mechanisms so you can map your objectives with outcomes and keep track of them in real-time. Our consultants help to identify, design and implement as per organization needs.',
          'col1-header': 'Transformation Indicators',
          'col2-header': 'Productivity Indicators',
          'col3-header': 'Realtime dashboards',
        }}
      />
    </React.Fragment>
  );
}

